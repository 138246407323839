import { jsonHeaders, logRequest } from 'api/utils';

import { URLS } from '../constants';

import checkConsentsResponse from './check-consents.json';
import infoResponse from './info.json';
import offersResponse from './offers.json';
import cartResponse from './cart.json';
import urlResponse from './url.json';
import clientInfo from './client-info.json';
import consentEcp from './consent-ecp.json';
import consentPdf from './consent-pdf.json';
import sendSmsCode from './send-sms-code.json';
import checkSmsCode from './check-sms-code.json';

function checkConsents(config) {
  return logRequest(config, [200, checkConsentsResponse, jsonHeaders]);
}

function getApplicationInfo(config) {
  return logRequest(config, [200, infoResponse, jsonHeaders]);
}

function getApplicationOffers(config) {
  return logRequest(config, [200, offersResponse, jsonHeaders]);
}

function getApplicationCart(config) {
  return logRequest(config, [200, cartResponse, jsonHeaders]);
}

function getApplicationRedirectUrl(config) {
  return logRequest(config, [200, urlResponse, jsonHeaders]);
}

function getClientInfo(config) {
  return logRequest(config, [200, clientInfo, jsonHeaders]);
}

function getConsentEcp(config) {
  return logRequest(config, [200, consentEcp, jsonHeaders]);
}

function getConsentPdf(config) {
  return logRequest(config, [200, consentPdf, jsonHeaders]);
}

function postSendSmsCode(config) {
  return logRequest(config, [200, sendSmsCode, jsonHeaders]);
}

function postCheckSmsCode(config) {
  return logRequest(config, [200, checkSmsCode, jsonHeaders]);
}

function postSendPreferences(config) {
  return logRequest(config, [200, {}, jsonHeaders]);
}

export function frontApiMock(axiosMockInstance) {
  axiosMockInstance.onGet(new RegExp(`^${URLS.CHECK_CONSENTS.replace(':id', '[^/]+')}$`)).reply(checkConsents);
  axiosMockInstance
    .onGet(new RegExp(`^${URLS.GET_APPLICATION_INFO.replace(':id', '[^/]+')}$`))
    .reply(getApplicationInfo);
  axiosMockInstance
    .onGet(new RegExp(`^${URLS.GET_APPLICATION_OFFERS.replace(':id', '[^/]+')}$`))
    .reply(getApplicationOffers);
  axiosMockInstance
    .onGet(new RegExp(`^${URLS.GET_APPLICATION_CART.replace(':id', '[^/]+')}$`))
    .reply(getApplicationCart);
  axiosMockInstance.onGet(new RegExp(`^${URLS.GET_CLIENT_INFO.replace(':id', '[^/]+')}$`)).reply(getClientInfo);
  axiosMockInstance
    .onGet(new RegExp(`^${URLS.GET_APPLICATION_REDIRECT_URL.replace(':id', '[^/]+')}$`))
    .reply(getApplicationRedirectUrl);
  axiosMockInstance.onGet(new RegExp(`^${URLS.GET_CONSENT_ECP.replace(':id', '[^/]+')}$`)).reply(getConsentEcp);
  axiosMockInstance.onGet(new RegExp(`^${URLS.GET_CONSENT_PDF.replace(':id', '[^/]+')}$`)).reply(getConsentPdf);
  axiosMockInstance.onPost(new RegExp(`^${URLS.SEND_SMS_CODE.replace(':id', '[^/]+')}$`)).reply(postSendSmsCode);
  axiosMockInstance.onPost(new RegExp(`^${URLS.CHECK_SMS_CODE.replace(':id', '[^/]+')}$`)).reply(postCheckSmsCode);
  axiosMockInstance.onPost(new RegExp(`^${URLS.SEND_PREFERENCES.replace(':id', '[^/]+')}$`)).reply(postSendPreferences);
}
