import { decisionInfoResponse } from './index';

const { logRequest, jsonHeaders } = require('api/utils');

function getDecisionApplicationInfo(config, infoKey) {
  const responseInfo = decisionInfoResponse[infoKey];
  if (!responseInfo) {
    throw new Error(`Information for key "${infoKey}" not found.`);
  }
  return logRequest(config, [200, responseInfo, jsonHeaders]);
}

export default {
  getDecisionApplicationInfo1: config => getDecisionApplicationInfo(config, 'info1'),
  getDecisionApplicationInfo2: config => getDecisionApplicationInfo(config, 'info2'),
  getDecisionApplicationInfo3: config => getDecisionApplicationInfo(config, 'info3'),
  getDecisionApplicationInfo4: config => getDecisionApplicationInfo(config, 'info4'),
  getDecisionApplicationInfo5: config => getDecisionApplicationInfo(config, 'info5'),
};
