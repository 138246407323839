import { jsonHeaders, logRequest } from 'api/utils';

import { URLS, APPLICATION_STATUSES, APPLICATION_STEPS, APPLICATION_LIMIT_PROCESS_STATUSES } from '../constants';

import statusResponse from './status.json';
import limitsResponse from './limits.json';
import requirementsDictionary from './requirementsDictionary.json';
import requirements from './requirements.json';
import offers from './offers.json';
import cart from './cart.json';
import decisions from './decisions.json';

let status = null;
let step = null;

function getStatus(config) {
  if (step === APPLICATION_STEPS.START_SIGNING_PROCESS) status = APPLICATION_STATUSES.SIGNING_PROCESS;
  else if (status === APPLICATION_STATUSES.SIGNING_PROCESS) status = APPLICATION_STATUSES.SIGNED;
  // else status = APPLICATION_STATUSES.NEW;
  else status = APPLICATION_STATUSES.WAIT_APPROVE;
  // else if (!status) status = APPLICATION_STATUSES.SIGNING_PROCESS;
  // else if (!status) status = APPLICATION_STATUSES.SIGNED;
  // else if (!status) status = APPLICATION_STATUSES.REJECTED;
  // else if (!status) status = APPLICATION_STATUSES.ERROR;
  // else if (!status) status = APPLICATION_STATUSES.CANCELLED;
  return logRequest(config, [200, { ...statusResponse, status }, jsonHeaders]);
}

function getStep(config) {
  if (step === APPLICATION_STEPS.FILL_ADDITIONAL_FORM_DATA) step = APPLICATION_STEPS.SELECT_PREFERENCES;
  else if (step === APPLICATION_STEPS.SELECT_PREFERENCES) step = APPLICATION_STEPS.SELECT_PROVIDER;
  else if (step === APPLICATION_STEPS.SELECT_PROVIDER) step = APPLICATION_STEPS.WAIT;
  else if (step === APPLICATION_STEPS.WAIT) step = APPLICATION_STEPS.SELECT_OFFER;
  else if (step === APPLICATION_STEPS.SELECT_OFFER) step = APPLICATION_STEPS.SELECT_SIGN_TYPE;
  else if (step === APPLICATION_STEPS.SELECT_SIGN_TYPE) step = APPLICATION_STEPS.START_SIGNING_PROCESS;
  else if (step === APPLICATION_STEPS.START_SIGNING_PROCESS) step = null;
  else step = APPLICATION_STEPS.WAIT_LIMITS;
  // else step = APPLICATION_STEPS.FILL_ADDITIONAL_FORM_DATA;
  // else step = APPLICATION_STEPS.SELECT_PREFERENCES;
  // else step = APPLICATION_STEPS.SELECT_PROVIDER;

  return logRequest(config, [200, step, jsonHeaders]);
}

function getEnrichment(config) {
  return logRequest(config, [200, true, jsonHeaders]);
}

let getLimitsCount = 0;
let limitProcessStatus = null;
function getLimits(config) {
  if (limitProcessStatus === APPLICATION_LIMIT_PROCESS_STATUSES.PENDING) {
    if (getLimitsCount < 1) getLimitsCount += 1;
    else limitProcessStatus = APPLICATION_LIMIT_PROCESS_STATUSES.LIMIT_APPROVED;
  } else limitProcessStatus = APPLICATION_LIMIT_PROCESS_STATUSES.PENDING;

  return logRequest(config, [200, { ...limitsResponse, limitProcessStatus }, jsonHeaders]);
}

const getRequirementsDictionary = config => {
  return logRequest(config, [200, requirementsDictionary, jsonHeaders]);
};

const getRequirements = config => {
  return logRequest(config, [200, requirements, jsonHeaders]);
};

const getOffers = config => {
  return logRequest(config, [200, offers, jsonHeaders]);
};

const getCart = config => {
  return logRequest(config, [200, cart, jsonHeaders]);
};

const sendPreferences = config => logRequest(config, [200, true, jsonHeaders]);

let getDecisionsCount = 0;
const getDecisions = config => {
  getDecisionsCount += 1;
  return logRequest(config, [200, decisions[getDecisionsCount.toString()], jsonHeaders]);
};

const sendProvider = config => logRequest(config, [200, true, jsonHeaders]);

const sendOffer = config => logRequest(config, [200, true, jsonHeaders]);

const sendSignType = config => logRequest(config, [200, true, jsonHeaders]);

const sendStartSigningProcess = config => logRequest(config, [200, true, jsonHeaders]);

const sendSmsToClient = config => logRequest(config, [200, true, jsonHeaders]);

export function frontApiMock(axiosMockInstance) {
  axiosMockInstance.onGet(new RegExp(`^${URLS.GET_STATUS.replace(':id', '[^/]+')}$`)).reply(getStatus);
  axiosMockInstance.onGet(new RegExp(`^${URLS.GET_STEP.replace(':id', '[^/]+')}$`)).reply(getStep);
  axiosMockInstance.onPost(new RegExp(`^${URLS.GET_ENRICHMENT.replace(':id', '[^/]+')}$`)).reply(getEnrichment);
  axiosMockInstance.onGet(new RegExp(`^${URLS.GET_LIMITS.replace(':id', '[^/]+')}$`)).reply(getLimits);
  axiosMockInstance.onGet(URLS.GET_REQUIREMENTS_DICTIONARY).reply(getRequirementsDictionary);
  axiosMockInstance.onGet(new RegExp(`^${URLS.GET_REQUIREMENTS.replace(':id', '[^/]+')}$`)).reply(getRequirements);
  axiosMockInstance.onGet(new RegExp(`^${URLS.GET_OFFERS.replace(':id', '[^/]+')}$`)).reply(getOffers);
  axiosMockInstance.onGet(new RegExp(`^${URLS.GET_CART.replace(':id', '[^/]+')}$`)).reply(getCart);
  axiosMockInstance.onPost(new RegExp(`^${URLS.SEND_PREFERENCES.replace(':id', '[^/]+')}$`)).reply(sendPreferences);
  axiosMockInstance.onGet(new RegExp(`^${URLS.GET_DECISIONS.replace(':id', '[^/]+')}$`)).reply(getDecisions);
  axiosMockInstance
    .onPatch(new RegExp(`^${URLS.SEND_PROVIDER.replace(':id', '[^/]+').replace(':providerId', '[^/]+')}$`))
    .reply(sendProvider);
  axiosMockInstance
    .onPatch(new RegExp(`^${URLS.SEND_OFFER.replace(':id', '[^/]+').replace(':offerId', '[^/]+')}$`))
    .reply(sendOffer);
  axiosMockInstance
    .onPost(new RegExp(`^${URLS.SEND_SIGN_TYPE.replace(':id', '[^/]+').replace(':signType', '[^/]+')}$`))
    .reply(sendSignType);
  axiosMockInstance
    .onPost(new RegExp(`^${URLS.SEND_START_SIGNING_PROCESS.replace(':id', '[^/]+').replace(':signType', '[^/]+')}$`))
    .reply(sendStartSigningProcess);
  axiosMockInstance.onPost(new RegExp(`^${URLS.SEND_SMS_TO_CLIENT.replace(':id', '[^/]+')}$`)).reply(sendSmsToClient);
}

const sendDocument = () => {
  return [200, 'hgghjg-543543-345435-kjhkj', jsonHeaders];
};

const sendDocuments = config => logRequest(config, [200, { resultCode: 'Ok' }, jsonHeaders]);

export function documentApiMock(axiosMockInstance) {
  axiosMockInstance
    .onPost(new RegExp(`^${URLS.SEND_DOCUMENT.replace(':id', '[^/]+').replace(':docType', '[^/]+')}$`))
    .reply(sendDocument);
  axiosMockInstance.onPost(new RegExp(`^${URLS.SEND_DOCUMENTS.replace(':id', '[^/]+')}$`)).reply(sendDocuments);
}
