import { jsonHeaders, logRequest } from 'api/utils';

import { URLS } from '../constants';

import applicationInfo from './application-info.json';
import clientInfo from './client-info.json';
import applicationActions from './application-actions.json';

import consentKnk from './consent-knk.json';
import consentKnkSes from './consent-knk-ses.json';
import documentRequirements from './document-requirements.json';
import getDocumentResponse from './get-document.json';
import sendDocumentResponse from './send-document.json';

let step = null;

function getApplicationInfo(config) {
  // if (step === 'LK_REGISTRATION_START') step = 'ATTACH_DOCS';
  /* не проходим все предыдущие шаги */
  if (step === 'LK_REGISTRATION_START') step = 'LK_REGISTRATION_CODE_MISMATCH';
  else if (step === 'LK_REGISTRATION_CODE_MISMATCH') step = 'LK_REGISTRATION_CODE_MISMATCH_ALL';
  else if (step === 'LK_REGISTRATION_CODE_MISMATCH_ALL') step = 'LK_REGISTRATION_CONTINUE';
  else if (step === 'LK_REGISTRATION_CONTINUE') step = 'ATTACH_DOCS';
  else if (step === 'ATTACH_DOCS') step = 'WAIT_1';
  else if (step === 'WAIT_1') step = 'PROFILE_INFO';
  else if (step === 'REATTACH_DOCS') step = 'WAIT_2';
  else if (step === 'WAIT_2') step = 'PROFILE_INFO';
  else if (step === 'PROFILE_INFO') step = 'WAIT_3';
  else if (step === 'WAIT_3') step = 'MKK_CONSENT';
  else if (step === 'MKK_CONSENT') step = 'SIGNING_PROCESS';
  else if (!step) step = 'ATTACH_DOCS';
  // else if (!step) step = 'WAIT_VDO_5361';

  return logRequest(config, [200, applicationInfo[step], jsonHeaders]);
}

function getClientInfo(config) {
  return logRequest(config, [200, clientInfo, jsonHeaders]);
}

function getApplicationActions(config) {
  return logRequest(config, [200, applicationActions, jsonHeaders]);
}

function patchClientInfo(config) {
  return logRequest(config, [200, clientInfo, jsonHeaders]);
}

const sendSmsCode = config => {
  return logRequest(config, [204, { resultCode: 'OK' }, jsonHeaders]);
};

const reattachDocs = config => {
  return logRequest(config, [204, { resultCode: 'OK' }, jsonHeaders]);
};

const signByCode = config => {
  return logRequest(config, [204, { resultCode: 'OK' }, jsonHeaders]);
};

const getDocumentRequirements = config => logRequest(config, [200, documentRequirements[step], jsonHeaders]);

export function frontApiMock(axiosMockInstance) {
  axiosMockInstance
    .onGet(new RegExp(`^${URLS.GET_APPLICATION_INFO.replace(':id', '[^/]+')}$`))
    .reply(getApplicationInfo);
  axiosMockInstance.onGet(new RegExp(`^${URLS.GET_CLIENT_INFO.replace(':id', '[^/]+')}$`)).reply(getClientInfo);
  axiosMockInstance
    .onGet(new RegExp(`^${URLS.GET_APPLICATION_ACTIONS.replace(':id', '[^/]+')}$`))
    .reply(getApplicationActions);
  axiosMockInstance.onPost(new RegExp(`^${URLS.SIGN_BY_CODE.replace(':id', '[^/]+')}$`)).reply(signByCode);
  axiosMockInstance.onPost(new RegExp(`^${URLS.SEND_SMS_CODE.replace(':id', '[^/]+')}$`)).reply(sendSmsCode);
  axiosMockInstance.onPut(new RegExp(`^${URLS.REATTACH_DOCS.replace(':id', '[^/]+')}$`)).reply(reattachDocs);
  axiosMockInstance
    .onGet(new RegExp(`^${URLS.GET_DOCUMENTS_REQUIREMENTS.replace(':id', '[^/]+')}$`))
    .reply(getDocumentRequirements);
  axiosMockInstance.onPatch(new RegExp(`^${URLS.PATCH_CLIENT_INFO.replace(':id', '[^/]+')}$`)).reply(patchClientInfo);
}

const getConsentKnk = config => logRequest(config, [200, consentKnk, jsonHeaders]);

const getConsentKnkSes = config => logRequest(config, [200, consentKnkSes, jsonHeaders]);

const getDocument = config => logRequest(config, [200, getDocumentResponse, jsonHeaders]);

const sendDocument = config => logRequest(config, [200, sendDocumentResponse, jsonHeaders]);

export function documentApiMock(axiosMockInstance) {
  axiosMockInstance.onGet(new RegExp(`^${URLS.GET_CONSENT_KNK.replace(':id', '[^/]+')}$`)).reply(getConsentKnk);
  axiosMockInstance.onGet(new RegExp(`^${URLS.GET_CONSENT_KNK_SES.replace(':id', '[^/]+')}$`)).reply(getConsentKnkSes);
  axiosMockInstance.onGet(new RegExp(`^${URLS.GET_DOCUMENT.replace(':s3Id', '[^/]+')}$`)).reply(getDocument);
  axiosMockInstance.onPost(new RegExp(`^${URLS.SEND_DOCUMENT.replace(':id', '[^/]+')}$`)).reply(sendDocument);
}
