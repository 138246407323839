export const URLS = {
  GET_APPLICATION_INFO: '/front-api/v1/applications/:id/info',
  GET_CLIENT_INFO: '/front-api/v1/blocks/:id/clientInfo',
  GET_APPLICATION_ACTIONS: '/front-api/v1/applications/:id/actions',
  SEND_SMS_CODE: '/front-api/v1/applications/:id/signing-process/send-sms-code',
  SIGN_BY_CODE: '/front-api/v1/applications/:id/signing-process/sign-by-code',
  REATTACH_DOCS: '/front-api/v1/applications/:id/documents/reattach',
  UNSELECT_DECISION: '/front-api/v1/applications/:id/decisions',
  GET_DOCUMENTS_REQUIREMENTS: '/front-api/v1/applications/:id/documents/requirements',
  PATCH_CLIENT_INFO: '/front-api/v1/online/applications/:id/clientInfo',

  GET_CONSENT_KNK: '/omay-document-front-api/v1/application/:id/consent-knk',
  GET_CONSENT_KNK_SES: '/omay-document-front-api/v1/online/applications/:id/documents?documentType=AGREEMENT_SES',
  GET_DOCUMENT: '/omay-document-front-api/v1/online/repository/documents/:s3Id',
  SEND_DOCUMENT: '/omay-document-front-api/v1/online/applications/:id/upload-and-save',
};

export const APPLICATION_STATUSES = {
  NEW: 'NEW',
  WAIT_APPROVE: 'WAIT_APPROVE',
  APPROVAL_PROCESS_FINISHED: 'APPROVAL_PROCESS_FINISHED',
  SIGNING_PROCESS: 'SIGNING_PROCESS',
  ERROR: 'ERROR',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  SIGNED: 'SIGNED',
};

export const APPLICATION_ACTIONS = {
  CHANGE_DECISION: 'CHANGE_DECISION',
  SIGN_BY_CODE: 'SIGN_BY_CODE',
  SEND_SMS_CODE: 'SEND_SMS_CODE',
  SEND_CODE: 'SEND_CODE',
  SEND_DOCUMENTS: 'SEND_DOCUMENTS',
  REATTACH_DOCS: 'REATTACH_DOCS',
  FILL_FORM_DATA: 'FILL_FORM_DATA',
  FILL_ADDITIONAL_FORM_DATA: 'FILL_ADDITIONAL_FORM_DATA',
  WAIT: 'WAIT',
  RESEND_CODE: 'RESEND_CODE',
  CANCEL: 'CANCEL',
};

export const APPLICATION_CATEGORY_CODES = {
  POS: 'POS',
};

export const APPLICATION_CREATION_TYPES = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
};

export const SELECTED_PROPOSAL_DETAILED_AVAILABLE_ACTIONS = {
  SIGN_BY_CODE: 'SIGN_BY_CODE',
  SEND_SMS_CODE: 'SEND_SMS_CODE',
  ATTACH_DOCS: 'ATTACH_DOCS',
  REATTACH_DOCS: 'REATTACH_DOCS',
  FILL_DATA: 'FILL_DATA',
};

export const SELECTED_PROPOSAL_DETAILED_AVAILABLE_ACTION_SIGN_BY_CODE_SUBJECTS = {
  LK_REGISTRATION: 'LK_REGISTRATION',
  MKK_CONSENT: 'MKK_CONSENT',
};
