import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { YMInitializer } from 'react-yandex-metrika';

import Application from 'application';
import { configureAxiosMockAdapter } from 'api/mock';

function Render() {
  return (
    <>
      {process.env.NODE_ENV !== 'development' && (
        <YMInitializer
          accounts={[53876074]}
          options={{ clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true }}
        />
      )}
      <Application />
    </>
  );
}

function init() {
  if (process.env.REACT_APP_MOCK) configureAxiosMockAdapter();
  ReactDOM.render(<Render />, document.getElementById('root'));
}

document.addEventListener('DOMContentLoaded', init);
