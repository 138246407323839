import { jsonHeaders, logRequest } from 'api/utils';

import { URLS } from '../constants';

import infoResponse from './info';
import regEx from './utils';
import decisionMocksFunc from './decisionMocks/utils';

import offersResponse from './offers.json';
import cartResponse from './cart.json';
import urlResponse from './url.json';
import limitsResponse from './limits.json';
import statusesResponse from './statuses.json';
import clientExternalProducts from './client-external-products.json';
import { APPLICATION_LIMIT_PROCESS_STATUSES } from '../../third-party';

const info = 'rejectedDeal';

const typeMock = 'decision2';

function getApplicationInfo(config) {
  return logRequest(config, [200, infoResponse[info], jsonHeaders]);
}

function getClientExternalProducts(config) {
  return logRequest(config, [200, clientExternalProducts, jsonHeaders]);
}

function getApplicationOffers(config) {
  return logRequest(config, [200, offersResponse, jsonHeaders]);
}
function getStatuses(config) {
  return logRequest(config, [200, statusesResponse, jsonHeaders]);
}

function getApplicationCart(config) {
  return logRequest(config, [200, cartResponse, jsonHeaders]);
}

function getApplicationRedirectUrl(config) {
  return logRequest(config, [200, urlResponse, jsonHeaders]);
}

let getLimitsCount = 0;
let limitProcessStatus = null;
function getApplicationLimitsInfo(config) {
  if (limitProcessStatus === APPLICATION_LIMIT_PROCESS_STATUSES.PENDING) {
    if (getLimitsCount < 1) getLimitsCount += 1;
    else limitProcessStatus = APPLICATION_LIMIT_PROCESS_STATUSES.LIMIT_APPROVED;
  } else limitProcessStatus = APPLICATION_LIMIT_PROCESS_STATUSES.PENDING;

  return logRequest(config, [200, { ...limitsResponse, limitProcessStatus }, jsonHeaders]);
}

function setupMockResponses(axiosMockInstance, regex, responses) {
  responses.forEach(response => {
    axiosMockInstance.onGet(regex).replyOnce(response);
  });
}

function processTypeMocks(axiosMockInstance) {
  const { applicationInfoRegex, selectProviderRegex, signTypeRegex } = regEx;
  const decisionResponses = [
    decisionMocksFunc.getDecisionApplicationInfo1,
    decisionMocksFunc.getDecisionApplicationInfo2,
    decisionMocksFunc.getDecisionApplicationInfo3,
    decisionMocksFunc.getDecisionApplicationInfo4,
  ];
  switch (typeMock) {
    case 'decision':
      setupMockResponses(axiosMockInstance, applicationInfoRegex, decisionResponses);
      axiosMockInstance.onPatch(selectProviderRegex).reply(200);
      axiosMockInstance.onPost(signTypeRegex).reply(200);
      axiosMockInstance.onGet(applicationInfoRegex).reply(decisionMocksFunc.getDecisionApplicationInfo5);
      break;
    default:
      axiosMockInstance.onGet(applicationInfoRegex).reply(getApplicationInfo);
      break;
  }
}

function setupStaticMocks(axiosMockInstance) {
  const staticRoutes = [
    { url: URLS.GET_LIMIT, handler: getClientExternalProducts },
    { url: URLS.GET_APPLICATION_OFFERS, handler: getApplicationOffers },
    { url: URLS.GET_APPLICATION_CART, handler: getApplicationCart },
    { url: URLS.GET_APPLICATION_REDIRECT_URL, handler: getApplicationRedirectUrl },
    { url: URLS.GET_APPLICATION_LIMITS_INFO, handler: getApplicationLimitsInfo },
    { url: URLS.GET_APPLICATION_DECISIONS, handler: getStatuses },
  ];

  staticRoutes.forEach(route => {
    const regex = new RegExp(`^${route.url.replace(':id', '[^/]+')}$`);
    axiosMockInstance.onGet(regex).reply(route.handler);
  });
}

export function frontApiMock(axiosMockInstance) {
  processTypeMocks(axiosMockInstance);
  setupStaticMocks(axiosMockInstance);
}
