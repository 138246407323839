import React from 'react';

const NotFound = React.lazy(() => import('./NotFound'));
const Order = React.lazy(() => import('./Order'));
const Webim = React.lazy(() => import('./Webim'));
const RemoteRegistration = React.lazy(() => import('./RemoteRegistration'));
const ThirdPartyOrder = React.lazy(() => import('./ThirdPartyOrder'));
const Documents = React.lazy(() => import('./Documents'));
const DocumentsNotFound = React.lazy(() => import('./DocumentsNotFound'));
const Attachments = React.lazy(() => import('./Attachments'));
const LiteApplication = React.lazy(() => import('./LiteApplication'));
const LiteOnlineApplication = React.lazy(() => import('./LiteOnlineApplication'));
const IdentificationKNK = React.lazy(() => import('./IdentificationKNK'));
const Agreements = React.lazy(() => import('./Agreements'));
const InitialPayment = React.lazy(() => import('./InitialPayment'));
const InitialPaymentSuccess = React.lazy(() => import('./InitialPayment/Success'));
const InitialPaymentFailed = React.lazy(() => import('./InitialPayment/Failed'));

const ROUTING = [
  {
    id: 'ORDER',
    path: '/order/:id',
    exact: true,
    Component: Order,
  },
  {
    id: 'ORDER_WEBIM',
    path: '/order/:id/webim',
    exact: true,
    Component: Webim,
  },
  {
    id: 'REMOTE_REGISTRATION',
    path: '/application/:id/remote-registration',
    exact: true,
    Component: RemoteRegistration,
  },
  {
    id: 'THIRD_PARTY_ORDER',
    path: '/application/:id/third-party',
    exact: true,
    Component: ThirdPartyOrder,
  },
  {
    id: 'DOCUMENTS',
    path: '/application/:id/doc-set',
    exact: true,
    Component: Documents,
  },
  {
    id: 'DOCUMENTS_NOT_FOUND',
    path: '/application/doc-set/not-found',
    exact: true,
    Component: DocumentsNotFound,
  },
  {
    id: 'ATTACHMENTS',
    path: '/application/attachments',
    exact: true,
    Component: Attachments,
  },
  {
    id: 'LITE_APPLICATION',
    path: '/lite-application/:partyId',
    exact: true,
    Component: LiteApplication,
  },
  {
    id: 'LITE_ONLINE_APPLICATION',
    path: '/lite-online-application/:partyId',
    exact: true,
    Component: LiteOnlineApplication,
  },
  {
    id: 'IDENTIFICATION_KNK',
    path: '/application/:id/identification-knk',
    exact: true,
    Component: IdentificationKNK,
  },
  {
    id: 'AGREEMENTS',
    path: '/application/:id/agreements',
    exact: true,
    Component: Agreements,
  },
  {
    id: 'INITIAL_PAYMENT_SUCCESS',
    path: '/initial-payment/success',
    exact: true,
    Component: InitialPaymentSuccess,
  },
  {
    id: 'INITIAL_PAYMENT_FAILED',
    path: '/initial-payment/failed',
    exact: true,
    Component: InitialPaymentFailed,
  },
  {
    id: 'INITIAL_PAYMENT',
    path: '/initial-payment/:agreementNumber',
    exact: true,
    Component: InitialPayment,
  },
  {
    id: 'NOT_FOUND',
    path: '/*',
    exact: false,
    Component: NotFound,
  },
];

export default ROUTING;
