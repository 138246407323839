export const URLS = {
  CHECK_CONSENTS: '/front-api/v1/applications/consent/:id/check',
  GET_APPLICATION_INFO: '/front-api/v1/applications/:id/info',
  GET_APPLICATION_OFFERS: '/front-api/v1/blocks/:id/offers',
  GET_APPLICATION_CART: '/front-api/v1/blocks/:id/cart',
  GET_CLIENT_INFO: '/front-api/v1/blocks/:id/clientInfo',
  SEND_PREFERENCES: '/front-api/v1/applications/:id/preferences',
  GET_APPLICATION_REDIRECT_URL: '/front-api/v1/applications/:id/url',
  SEND_SMS_CODE: '/front-api/v1/applications/:id/smsCode',
  CHECK_SMS_CODE: '/front-api/v1/applications/:id/consent',
  GET_APPLICATION_STATUS: '/front-api/v1/applications/:id/status',

  GET_CONSENT_PDF: '/omay-document-front-api/v1/application/{:id}/consent-pdf',
  GET_CONSENT_ECP: '/omay-document-front-api/v1/application/{:id}/consent-ecp',
};

export const APPLICATION_STATUSES = {
  NEW: 'NEW',
  WAIT_APPROVE: 'WAIT_APPROVE',
  APPROVAL_PROCESS_FINISHED: 'APPROVAL_PROCESS_FINISHED',
  SIGNING_PROCESS: 'SIGNING_PROCESS',
  ERROR: 'ERROR',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  SIGNED: 'SIGNED',
};

export const APPLICATION_ACTIONS = {
  SAVE_USER_DEVICE_DATA: 'SAVE_USER_DEVICE_DATA',
  SIGN_BY_CODE: 'SIGN_BY_CODE',
  RESEND_CODE: 'RESEND_CODE',
  SEND_SMS_CODE: 'SEND_SMS_CODE',
  CONFIRM_CONSENTS: 'CONFIRM_CONSENTS',
};

export const EXTRA_PRODUCTS_TYPES = {
  SL: 'SL',
  SOPR: 'SL_SOPR',
  SMS: 'SMS',
};

export const EXTRA_PRODUCTS_PAYMENT_TYPES = {
  IN_BODY: 'IN_BODY',
  OUT_OF_BODY: 'OUT_OF_BODY',
};

export const SMS_CODE_VERIFICATION_RESULTS = {
  MISMATCH_TRY_AGAIN: 'MISMATCH_TRY_AGAIN',
  MISMATCH_NO_ATTEMPTS: 'MISMATCH_NO_ATTEMPTS',
  SESSION_CLOSED: 'SESSION_CLOSED',
  OOPS_ERROR: 'OOPS_ERROR',
  SAVING_DOCS_ERROR: 'SAVING_DOCS_ERROR',
  OK: 'OK',
};
