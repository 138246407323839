import active from './active.json';
import canceled from './canceled.json';
import cancellationProcess from './cancellation-process.json';
import completionAtSellerplace from './completion-at-sellerplace.json';
import error from './error.json';
import rejected from './rejected.json';
import rejectedByDelivery from './rejected-by-delivery.json';
import signed from './signed.json';
import signingProcess from './signing-process.json';
import waitCall from './wait-call.json';
import waitLimits from './wait-limits.json';
import limitsRejected from './limits-rejected.json';
import rejectedDeal from './rejected-deal-conclusion.json';

export default {
  active,
  canceled,
  cancellationProcess,
  completionAtSellerplace,
  error,
  rejected,
  rejectedByDelivery,
  signed,
  signingProcess,
  waitCall,
  waitLimits,
  limitsRejected,
  rejectedDeal,
};
