export const URLS = {
  GET_STATUS: '/front-api/v1/applications/{:id}/status',
  GET_CART: '/front-api/v1/blocks/{:id}/cart',
  GET_OFFERS: '/front-api/v1/blocks/{:id}/offers',
  SEND_PREFERENCES: '/front-api/v1/applications/{:id}/preferences',
  GET_DECISIONS: '/front-api/v1/applications/{:id}/statuses',
  SEND_DECISION: '/front-api/v2/applications/{:id}/proposals/{:proposalId}',
  REQUEST_OTHER_DECISIONS: '/front-api/v1/applications/{:id}/other-decisions',
  SEND_OFFER: '/front-api/v1/applications/{:id}/offers/{:offerId}',
  SEND_SIGN_TYPE: '/front-api/v1/applications/{:id}/signing-process/signtypes/{:signType}',
  SIGNING_PROCESS_START_SIGNING_PROCESS: '/front-api/v1/applications/{:id}/signing-process/start/{:signType}',
  GET_SELECTED_PROVIDER: '/front-api/v1/applications/{:id}/selected-provider',
  CHECK_CONSENT: '/front-api/v1/applications/check-consent',
  SIGNING_PROCESS_GET_DOCUMENTS: '/omay-document-front-api/v1/online/applications/{:id}/doc-set',
  SIGNING_PROCESS_SEND_SIGN: '/front-api/v1/applications/{:id}/signing-process/sign',
  SIGNING_PROCESS_SIGN_BY_CODE: '/front-api/v1/applications/{:id}/signing-process/sign-by-code',
  SIGNING_PROCESS_SEND_SMS_CODE: '/front-api/v1/applications/{:id}/signing-process/send-sms-code',
  CHECK_URL: '/url/check',
  GET_BARCODES: '/front-api/v1/reports/partner-goods-list/{:id}/barcodes',
};

export const ORDER_STATUSES = {
  INIT: 'INIT',
  WAIT: 'WAIT',

  NEW: 'NEW',
  WAIT_APPROVE: 'WAIT_APPROVE',
  APPROVAL_PROCESS_FINISHED: 'APPROVAL_PROCESS_FINISHED',
  SIGNING_PROCESS: 'SIGNING_PROCESS',
  SIGNED: 'SIGNED',
  ACTIVE: 'ACTIVE',
  ERROR: 'ERROR',
  REJECTED: 'REJECTED',
  CANCELED: 'CANCELED',
};

export const APPLICATION_STATUSES = {
  NEW: 'NEW',
  WAIT_APPROVE: 'WAIT_APPROVE',
  APPROVAL_PROCESS_FINISHED: 'APPROVAL_PROCESS_FINISHED',
  PROVIDER_SELECTED: 'PROVIDER_SELECTED',
  SIGNING_PROCESS: 'SIGNING_PROCESS',
  SIGNED: 'SIGNED',
  ACTIVE: 'ACTIVE',
  CANCELLATION_PROCESS: 'CANCELLATION_PROCESS',
  CANCELED: 'CANCELED',
  REJECTED: 'REJECTED',
  ERROR: 'ERROR',
  IN_SIGNING: 'IN_SIGNING',
  CONFIRMED: 'CONFIRMED',
  PRE_APPROVED: 'PRE_APPROVED',
};

export const APPLICATION_ACTIONS = {
  WAIT: 'WAIT',
  SELECT_PROVIDER: 'SELECT_PROVIDER',
  SELECT_OFFER: 'SELECT_OFFER',
  START_SIGNING_PROCESS: 'START_SIGNING_PROCESS',
  SELECT_SIGN_TYPE: 'SELECT_SIGN_TYPE',
  SEND_SMS_CODE: 'SEND_SMS_CODE',
  SEND_CODE: 'SEND_CODE',
  SIGN: 'SIGN',
  SIGN_BY_CODE: 'SIGN_BY_CODE',
  SEND_DOCUMENTS: 'SEND_DOCUMENTS',
  PRINT_DOCUMENT: 'PRINT_DOCUMENT',
  GET_CONFIRMATION_CODE_SIGNING_STATE: 'GET_CONFIRMATION_CODE_SIGNING_STATE',
};

export const APPLICATION_CATEGORY_CODES = {
  POS: 'POS',
};

export const APPLICATION_CREATION_TYPES = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
};

export const APPLICATION_PROCESS_TYPES = {
  FREE_ACCREDITATION: 'FREE_ACCREDITATION',
};

export const EXTRA_PRODUCTS_TYPES = {
  SL: 'SL',
  SL_SOPR: 'SL_SOPR',
  SOPR: 'SOPR',
  SMS: 'SMS',
};

export const EXTRA_PRODUCTS_PAYMENT_TYPES = {
  IN_BODY: 'IN_BODY',
  OUT_OF_BODY: 'OUT_OF_BODY',
};

export const DECISIONS_STATUSES = {
  NOT_CREATED: 'NOT_CREATED',
  NEW: 'NEW',
  WAIT_APPROVE: 'WAIT_APPROVE',
  PRE_APPROVED: 'PRE_APPROVED',
  CONFIRMED: 'CONFIRMED',
  SIGNED: 'SIGNED',
  ACTIVE: 'ACTIVE',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  ERROR: 'ERROR',
};

export const SIGNING_TYPES = {
  MANUAL: 'MANUAL',
  SMS: 'SMS',
  PARTNER_SMS: 'PARTNER_SMS',
  PARTNER_FRONT_SMS: 'PARTNER_FRONT_SMS',
};

export const SIGN_AGREEMENT_TYPES = {
  TRANSFER_OF_CLAIM_TO_THIRD_PARTY: 'TRANSFER_OF_CLAIM_TO_THIRD_PARTY',
  REFUSE_OF_ADVERTISING: 'REFUSE_OF_ADVERTISING',
};

export const SIGN_SUBJECT_TYPES = {
  SERVICE: 'SERVICE',
  CONTRACT: 'CONTRACT',
  PACKAGE: 'PACKAGE',
};

export const PROPOSAL_STATUSES = {
  NEW: 'NEW',
  PRE_APPROVED: 'PRE_APPROVED',
  CONFIRMED: 'CONFIRMED',
  SIGNED: 'SIGNED',
  ACTIVE: 'ACTIVE',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  WAIT_APPROVE: 'WAIT_APPROVE',
  ERROR: 'ERROR',
};

export const PROPOSAL_DETAILED_ACTIONS = {
  GET_DOCS: 'GET_DOCS',
  SEND_SMS_CODE: 'SEND_SMS_CODE',
  SIGN_BY_CODE: 'SIGN_BY_CODE',
  WAIT: 'WAIT',
};

export const SMS_CODE_VERIFICATION_RESULTS = {
  MISMATCH_TRY_AGAIN: 'MISMATCH_TRY_AGAIN',
  MISMATCH_NO_ATTEMPTS: 'MISMATCH_NO_ATTEMPTS',
  SESSION_CLOSED: 'SESSION_CLOSED',
  OK: 'OK',
};
