import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import WebimChat from '@omay/webim-module/lib/WebimChat';
import { useLocation } from 'react-router-dom';

function Chat(props) {
  const { store } = props;
  const { mounted, mount, unmount, initialized, params } = store;
  const { pathname } = useLocation();

  useEffect(() => {
    if (!mounted) mount(pathname);

    return () => {
      if (mounted) unmount();
    };
  }, [mounted, mount, unmount, pathname]);

  if (!initialized) return null;

  const url =
    process.env.REACT_APP_WEBIM_URL !== 'https://webim.vsegda-da.com'
      ? process.env.REACT_APP_WEBIM_URL
      : 'https://webim.vsegda-da.com';

  return (
    <WebimChat
      accountName={params.accountName}
      domain={params.domain}
      location={params.location}
      url={url}
      showButton
    />
  );
}

Chat.propTypes = {
  store: PropTypes.shape().isRequired,
};

export default inject('store')(observer(Chat));
