/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import { jsonHeaders, logRequest } from 'api/utils';
import { URLS } from '../constants';

import status from './status.json';
import statuses from './statuses.json';
import selectedProvider from './selected-provider.json';
import consent from './consent.json';
import cart from './cart.json';
import offers from './offers.json';
import barcodes from './barcodes.json';
import signByCode from './sign-by-code.json';

const getSelectedProvider = ({ needDBO, n = 2, signType = 'PARTNER_SMS', signingProcessStep = 'CONFIRMED' } = {}) => {
  let iteration = 0;

  return config => {
    iteration += 1;
    console.log('%c%s', 'color: #6666fe;', 'getSelectedProvider', iteration); // eslint-disable-line

    return logRequest(config, [
      200,
      { ...selectedProvider[signingProcessStep], signType, needDBO: needDBO && n > iteration },
      jsonHeaders,
    ]);
  };
};

export function frontApiMock(axiosMockInstance) {
  axiosMockInstance
    .onGet(new RegExp(`^${URLS.GET_STATUS.replace('{:id}', '[^/]+')}$`))
    .reply(config => logRequest(config, [200, status, jsonHeaders]))

    .onGet(new RegExp(`^${URLS.GET_SELECTED_PROVIDER.replace('{:id}', '[^/]+')}$`))
    .reply(getSelectedProvider())

    .onPost(URLS.CHECK_CONSENT)
    .reply(config => logRequest(config, [200, consent, jsonHeaders]))

    .onGet(new RegExp(`^${URLS.GET_CART.replace('{:id}', '[^/]+')}$`))
    .reply(config => logRequest(config, [200, cart, jsonHeaders]))

    .onGet(new RegExp(`^${URLS.GET_OFFERS.replace('{:id}', '[^/]+')}$`))
    .reply(config => logRequest(config, [200, offers, jsonHeaders]))

    .onPost(new RegExp(`^${URLS.SEND_PREFERENCES.replace('{:id}', '[^/]+')}$`))
    .reply(config => logRequest(config, [204, '', jsonHeaders]))

    .onPost(new RegExp(`^${URLS.SIGNING_PROCESS_SEND_SIGN.replace('{:id}', '[^/]+')}$`))
    .reply(config => logRequest(config, [204, '', jsonHeaders]))

    .onGet(new RegExp(`^${URLS.GET_BARCODES.replace('{:id}', '[^/]+')}$`))
    .reply(config => logRequest(config, [204, barcodes, jsonHeaders]))

    .onGet(new RegExp(`^${URLS.GET_DECISIONS.replace('{:id}', '[^/]+')}$`))
    .reply(config => logRequest(config, [204, statuses, jsonHeaders]))

    .onPost(new RegExp(`^${URLS.SIGNING_PROCESS_SIGN_BY_CODE.replace('{:id}', '[^/]+')}$`))
    .reply(config => logRequest(config, [204, signByCode, jsonHeaders]));
}
