import { types } from 'mobx-state-tree';

import { checkHideUrls } from './utils';
import { EXCLUDED_ROUTES_DEPENDENCE, PARAMS } from './constants';

export const Store = types
  .model({
    mounted: types.boolean,
    initialized: types.boolean,
  })
  .views(() => ({
    get params() {
      return {
        accountName: PARAMS.ACCOUNT_NAME,
        domain: PARAMS.DOMAIN,
        location: PARAMS.LOCATION,
      };
    },
  }))
  .actions(self => ({
    mount: pathname => {
      self.mounted = true;
      self.initialized = !checkHideUrls(EXCLUDED_ROUTES_DEPENDENCE, pathname);
    },

    unmount: () => {
      self.mounted = false;
      self.initialized = false;
    },

    init: () => {
      self.initialized = true;
    },
  }));

export function create() {
  return Store.create({
    mounted: false,
    initialized: false,
  });
}

export const store = create();
