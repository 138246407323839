export const API_CANCELED_MESSAGE = 'canceled';

export const PROVIDER_INN = {
  TINKOFF: '7710140679',
  TFINANCE: '7704599714',
  SOVCOM: '4401116480',
  MTS: '7702045051',
  FREEDOM: '1111111111',
  HCFB: '7735057951',
  MKK_KNK: '7735104496',
  RENESSANS: '7744000126',
  VOSTBANK: '2801015394',
  RENMFO: '7710507430',
  KVIKU: '7716748537',
};

export const PROVIDER_SETTINGS_MAPPING = {
  [PROVIDER_INN.TINKOFF]: { maxLength: 6, minLength: 6, type: 'digital' },
  [PROVIDER_INN.TFINANCE]: { maxLength: 6, minLength: 6, type: 'digital' },
  [PROVIDER_INN.SOVCOM]: { maxLength: 6, minLength: 6, type: 'digital' },
};

export const DOCUMENTS_TYPES = {
  PASSPORT_2_3P: 'PASSPORT_2_3P',
  PASSPORT_REG: 'PASSPORT_REG',
  BCI_PD_MTS: 'BCI_PD_MTS',
  PASSPORT_2_3P_CERTIFIED_COPY: 'PASSPORT_2_3P_CERTIFIED_COPY',
  PASSPORT_REG_CERTIFIED_COPY: 'PASSPORT_REG_CERTIFIED_COPY',
};

export const CUSTOM_APPLICATION_FRONTS = {
  SAMSUNG: 'samsung',
  BEELINE: 'beeline',
  SKILLBOX: 'skillbox',
  ROBOKASSA: 'robokassa',
  WORLD_CLASS: 'world_class',
  PORTABLE: 'portable',
};
