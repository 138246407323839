import React from 'react';
import { Provider } from 'mobx-react';

import Component from './Chat';
import { store } from './store';

export default function(props) {
  return (
    <Provider store={store}>
      <Component {...props} />
    </Provider>
  );
}
