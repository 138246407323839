export const URLS = {
  CREATE_APPLICATION: '/front-api/v1/applications',
  CREATE_ONLINE_APPLICATION: '/front-api/v1/applications/online',
  GET_APPLICATION_INFO: '/front-api/v1/applications/:id/info',
  GET_APPLICATION_OFFERS: '/front-api/v1/blocks/:id/offers',
  GET_APPLICATION_CART: '/front-api/v1/blocks/:id/cart',
  GET_APPLICATION_DECISIONS: '/front-api/v1/applications/:id/statuses',
  GET_APPLICATION_REDIRECT_URL: '/front-api/v1/applications/:id/url',
  GET_APPLICATION_CLIENT_INFO: '/front-api/v1/blocks/:id/clientInfo',
  GET_APPLICATION_LIMITS: '/front-api/v1/applications/limits',
  GET_APPLICATION_LIMITS_INFO: '/front-api/v1/applications/:id/limits',
  SEND_TIME_OFFSET: '/front-api/v1/applications/:id/time-offset',
  SEND_CART: '/front-api/v1/applications/:id',
  SEND_PREFERENCES: '/front-api/v1/applications/:id/preferences',
  REQUEST_OTHER_DECISIONS: '/front-api/v1/applications/:id/other-decisions',
  UNSELECT_DECISION: '/front-api/v1/applications/:id/decisions',
  SEND_DECISION: '/front-api/v2/applications/:id/decisions/:providerId',
  SEND_OFFER: '/front-api/v1/applications/:id/offers/:offerId',
  SEND_SIGN_TYPE: '/front-api/v1/applications/:id/signing-process/signtypes/:signType',
  START_SIGNING_PROCESS: '/front-api/v1/applications/:id/signing-process/start/:signType',
  SELECT_DOCS_RECEIVING_TYPE: '/front-api/v1/applications/:id/doc-receiving-type',
  CANCEL_ORDER: '/front-api/v1/applications/:id/cancel',

  GET_DOCUMENTS_REQUIREMENTS: '/front-api/v1/applications/:id/documents/requirements',
  REATTACH_DOCS: '/front-api/v1/applications/:id/documents/reattach',
  GET_APPLICATION_ACTIONS: '/front-api/v1/applications/:id/actions',
  GET_DOCUMENT_FROM_REPOSITORY: '/omay-document-front-api/v1/online/repository/documents/:s3Id',
  SEND_DOCUMENT: '/omay-document-front-api/v1/online/applications/:id/upload-and-save',
  GET_DOCUMENTS_TYPES: '/omay-document-front-api/v1/online/applications/:id/document-types',
  GET_DOCUMENT: '/omay-document-front-api/v1/online/documents/:docId',
  GET_SPECIFICATION: '/omay-document-front-api/v1/online/application/:id/partner-specification',
  CHECK_DOCS: '/omay-document-front-api/v1/online/applications/:id/recognition-check',

  SIGNING_PROCESS_SET_AGREEMENTS: '/front-api/v1/applications/:id/signing-process/agreements',
  SIGNING_PROCESS_SEND_SMS_CODE: '/front-api/v1/applications/:id/signing-process/send-sms-code',
  SIGNING_PROCESS_SIGN_BY_CODE: '/front-api/v1/applications/:id/signing-process/sign-by-code',
  SIGNING_PROCESS_SIGN: '/front-api/v1/applications/:id/signing-process/sign',

  SEND_USER_DEVICE: '/front-api/v1/applications/:id/user-device',
  GET_LIMIT: '/front-api/v1/applications/:id/client-external-products',
  GET_MOBILE_URL: '/front-api/v1/applications/:id/mobile-id-url',

  PROMOTION_APPLICATION: '/front-api/v1/applications/:id/continue-process',

  GET_BRAZILIAN_PAY_LINK: '/front-api/v1/applications/:id/brazilian-payment-link',
};

export const APPLICATION_STATUSES = {
  NEW: 'NEW',
  WAIT_APPROVE: 'WAIT_APPROVE',
  APPROVAL_PROCESS_FINISHED: 'APPROVAL_PROCESS_FINISHED',
  SIGNING_PROCESS: 'SIGNING_PROCESS',
  SIGNED: 'SIGNED',
  ACTIVE: 'ACTIVE',
  CANCELLATION_PROCESS: 'CANCELLATION_PROCESS',
  CANCELED: 'CANCELED',
  REJECTED: 'REJECTED',
  ERROR: 'ERROR',
  REJECTED_ERROR_CANCELED: 'REJECTED_ERROR_CANCELED',
};

export const NO_REQUEST_STATUS = ['ACTIVE', 'REJECTED', 'ERROR', 'SIGNED', 'CANCELED'];

export const APPLICATION_STEPS = {
  POS_SHORTDATA_FORM: 'POS_SHORTDATA_FORM',
  POS_ADDDATA_FORM: 'POS_ADDDATA_FORM',
  POS_PASSDATA_FORM: 'POS_PASSDATA_FORM',
  WAIT_APPROVE: 'WAIT_APPROVE',
  COMPLETION_AT_SELLERPLACE: 'COMPLETION_AT_SELLERPLACE',
  WAIT_CALL: 'WAIT_CALL',
  WAIT_SIGNING_PROCESS: 'WAIT_SIGNING_PROCESS',
  WAIT_LONG: 'WAIT_LONG',
  WAIT_LIMITS: 'WAIT_LIMITS',
  SHOW_ERROR: 'SHOW_ERROR',
  ERROR_SIGNING_PROCESS: 'ERROR_SIGNING_PROCESS',
  REJECTED_DEAL_CONCLUSION: 'REJECTED_DEAL_CONCLUSION',
  START_SIGNING_PROCESS: 'START_SIGNING_PROCESS',
  SHOW_LIMIT: 'SHOW_LIMIT',
  ULTRA_SHORT_DATA_FORM: 'ULTRA_SHORT_DATA_FORM',
  PREPARATION_ADD_DATA_FORM: 'PREPARATION_ADD_DATA_FORM',
  IDX_AUTHENTICATION: 'IDX_AUTHENTICATION',
  IDX_AUTHENTICATION_SMS_SIGNING: 'IDX_AUTHENTICATION_SMS_SIGNING',
  COLLECT_CONSENTS_WITH_SMS: 'COLLECT_CONSENTS_WITH_SMS',
  SIGNING_AT_SELLERPLACE: 'SIGNING_AT_SELLERPLACE',
  REDIRECT_TO_MAP_WITH_BANKS: 'REDIRECT_TO_MAP_WITH_BANKS',
  REDIRECT_TO_ONLINE_BANK: 'REDIRECT_TO_ONLINE_BANK',
  REJECTED_BANKS_WITH_KNK_LIMIT: 'REJECTED_BANKS_WITH_KNK_LIMIT',
};

export const APPLICATION_ACTIONS = {
  WAIT: 'WAIT',
  CONTINUE: 'CONTINUE',
  SAVE_CONSENT: 'SAVE_CONSENT',
  FILL_CART_DATA: 'FILL_CART_DATA',
  FILL_PRODUCT_DATA: 'FILL_PRODUCT_DATA',
  FILL_ADDITIONAL_FORM_DATA: 'FILL_ADDITIONAL_FORM_DATA',
  SELECT_PROVIDER: 'SELECT_PROVIDER',
  SIGN: 'SIGN',
  CHANGE_DECISION: 'CHANGE_DECISION',
  START_SIGNING_PROCESS: 'START_SIGNING_PROCESS',
  SEND_DOCUMENTS: 'SEND_DOCUMENTS',
  PRINT_DOCUMENT: 'PRINT_DOCUMENT',
  SET_AGREEMENTS: 'SET_AGREEMENTS',
  SELECT_OFFER: 'SELECT_OFFER',
  SELECT_SIGN_TYPE: 'SELECT_SIGN_TYPE',
  SEND_SMS_CODE: 'SEND_SMS_CODE',
  SEND_CODE: 'SEND_CODE',
  SELECT_DOCUMENTATION_RECEIVING_TYPE: 'SELECT_DOCUMENTATION_RECEIVING_TYPE',

  FILL_FORM_DATA: 'FILL_FORM_DATA',
  CANCEL: 'CANCEL',
  RESTART_SIGNING_PROCESS: 'RESTART_SIGNING_PROCESS',
  ATTACH_DOCS: 'ATTACH_DOCS',
  REATTACH_DOCS: 'REATTACH_DOCS',
  SEND_ASSIGNMENT: 'SEND_ASSIGNMENT',
  REQUEST_OTHER_DECISIONS: 'REQUEST_OTHER_DECISIONS',
  SIGN_BY_CODE: 'SIGN_BY_CODE',
  SET_GOODS_DELIVERY: 'SET_GOODS_DELIVERY',
  SEND_PARTNER_GOODS_LIST: 'SEND_PARTNER_GOODS_LIST',
  CHANGE_SIGNING_TYPE: 'CHANGE_SIGNING_TYPE',
  WAIT_NOTHING: 'WAIT_NOTHING',
  SAVE_USER_DEVICE_DATA: 'SAVE_USER_DEVICE_DATA',
};

export const APPLICATION_SIGNING_TYPES = {
  MANUAL: 'MANUAL',
  SMS: 'SMS',
  PARTNER_SMS: 'PARTNER_SMS',
  PARTNER_FRONT_SMS: 'PARTNER_FRONT_SMS',
};

export const DECISIONS_PROVIDER_FINISH_TYPES = {
  REMOTE: 'REMOTE',
  OFFICE: 'OFFICE',
};

export const DECISIONS_STATUSES = {
  NOT_CREATED: 'NOT_CREATED',
  NEW: 'NEW',
  WAIT_APPROVE: 'WAIT_APPROVE',
  PRE_APPROVED: 'PRE_APPROVED',
  CONFIRMED: 'CONFIRMED',
  SIGNED: 'SIGNED',
  ACTIVE: 'ACTIVE',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  ERROR: 'ERROR',
};

export const DECISIONS_DEAL_TYPES = {
  CREDIT_LINE_DEAL: 'CREDIT_LINE_DEAL',
  CREDIT_LINE_TRANCHE_DEAL: 'CREDIT_LINE_TRANCHE_DEAL',
};

export const DEAL_TYPE = {
  CREDIT_LINE: 'CREDIT_LINE',
  CREDIT_LINE_DEAL: 'CREDIT_LINE_DEAL',
  CREDIT_LINE_TRANCHE: 'CREDIT_LINE_TRANCHE',
};

export const PROCESS_TYPE = {
  CREDIT_LINE: 'CREDIT_LINE',
  GIFT_CREDIT_LINE: 'GIFT_CREDIT_LINE',
  CREDIT_LINE_TRANCHE: 'CREDIT_LINE_TRANCHE',
  TRANCHE_PAYMENT: 'TRANCHE_PAYMENT',
};

export const EXTRA_PRODUCTS_TYPES = {
  SL: 'SL',
  SOPR: 'SL_SOPR',
  SMS: 'SMS',
};

export const EXTRA_PRODUCTS_PAYMENT_TYPES = {
  IN_BODY: 'IN_BODY',
  OUT_OF_BODY: 'OUT_OF_BODY',
};

export const PROPOSAL_DETAILED_ACTIONS = {
  FILL_DATA: 'FILL_DATA',
  SELECT_SIGN_TYPE: 'SELECT_SIGN_TYPE',
  GET_DOCS: 'GET_DOCS',
  SET_AGREEMENTS: 'SET_AGREEMENTS',
  SEND_SMS_CODE: 'SEND_SMS_CODE',
  SIGN_BY_CODE: 'SIGN_BY_CODE',
  SIGN: 'SIGN',
  WAIT: 'WAIT',
  SET_GOODS_DELIVERY: 'SET_GOODS_DELIVERY',
  FOLLOW_EXT_PROCESS: 'FOLLOW_EXT_PROCESS',
  ATTACH_DOCS: 'ATTACH_DOCS',
  UNDEFINED: 'UNDEFINED',
  CREDIT_LINE_TRANCHE_DEAL: 'CREDIT_LINE_TRANCHE_DEAL',
};

export const SIGN_SUBJECT_TYPES = {
  SERVICE: 'SERVICE',
  CONTRACT: 'CONTRACT',
  PACKAGE: 'PACKAGE',
  TRANCHE: 'TRANCHE',
};

export const DOCUMENTS_TYPES = {
  BCI_PD: 'BCI_PD',
  PASSPORT_2_3P: 'PASSPORT_2_3P',
  PASSPORT_REG: 'PASSPORT_REG',
  CLIENT_PHOTO: 'CLIENT_PHOTO',
  APPLICATION_PAGE_1: 'APPLICATION_PAGE_1',
  APPLICATION_PAGE_2: 'APPLICATION_PAGE_2',
  GOODS_LIST: 'GOODS_LIST',
  PROVIDER_GOODS_LIST: 'PROVIDER_GOODS_LIST',
  PAYMENT_SCHEDULE: 'PAYMENT_SCHEDULE',
  INDIV_CONDITIONS: 'INDIV_CONDITIONS',
  MEMO: 'MEMO',
  PARTNER_MEMO: 'PARTNER_MEMO',
  GENERAL_TERMS: 'GENERAL_TERMS',
  DOCS_SET: 'DOCS_SET',
  LIFE_INSURANCE: 'LIFE_INSURANCE',
  POLICY_APPLICATION: 'POLICY_APPLICATION',
  INSURANCE_POLICY_VSK: 'INSURANCE_POLICY_VSK',
  PROVIDER_DOCS_SET: 'PROVIDER_DOCS_SET',
  APPLICATION_TRANCHE: 'APPLICATION_TRANCHE',
  APP_OPEN_LOAN: 'APP_OPEN_LOAN',
  RECEIPT_TEMPLATE: 'RECEIPT_TEMPLATE',
};

export const DOCUMENTS_TYPES_LABELS = {
  [DOCUMENTS_TYPES.BCI_PD]: 'Согласие БКИ и Перс. данных',
  [DOCUMENTS_TYPES.PASSPORT_2_3P]: 'Паспорт РФ, страницы 2 и 3',
  [DOCUMENTS_TYPES.PASSPORT_REG]: 'Паспорт РФ, страница с регистрацией',
  [DOCUMENTS_TYPES.CLIENT_PHOTO]: 'Фотография клиента',
  [DOCUMENTS_TYPES.APPLICATION_PAGE_1]: 'Анкета банка. Лист 1',
  [DOCUMENTS_TYPES.APPLICATION_PAGE_2]: 'Анкета банка. Лист 2',
  [DOCUMENTS_TYPES.GOODS_LIST]: 'Спецификация товара',
  [DOCUMENTS_TYPES.PROVIDER_GOODS_LIST]: 'Спецификация товара',
  [DOCUMENTS_TYPES.PAYMENT_SCHEDULE]: 'Предварительный график платежей',
  [DOCUMENTS_TYPES.INDIV_CONDITIONS]: 'Индивидуальные условия по договору',
  [DOCUMENTS_TYPES.MEMO]: 'Памятка',
  [DOCUMENTS_TYPES.PARTNER_MEMO]: 'Общие условия',
  [DOCUMENTS_TYPES.GENERAL_TERMS]: 'Общие условия',
  [DOCUMENTS_TYPES.DOCS_SET]: 'Комплект документов',
  [DOCUMENTS_TYPES.LIFE_INSURANCE]: 'Полис страхования жизни',
  [DOCUMENTS_TYPES.POLICY_APPLICATION]: 'Заявление на страхование товара',
  [DOCUMENTS_TYPES.INSURANCE_POLICY_VSK]: 'Полис страхования жизни',
  [DOCUMENTS_TYPES.PROVIDER_DOCS_SET]: 'Комплект документов',
  [DOCUMENTS_TYPES.APPLICATION_TRANCHE]: 'Заявление на транш',
  [DOCUMENTS_TYPES.APP_OPEN_LOAN]: 'Заявление о предоставлении кредита/займа',
  [DOCUMENTS_TYPES.RECEIPT_TEMPLATE]: 'Бланк почтового перевода',
};

export const SIGN_AGREEMENT_TYPES = {
  TRANSFER_OF_CLAIM_TO_THIRD_PARTY: 'TRANSFER_OF_CLAIM_TO_THIRD_PARTY',
  REFUSE_OF_ADVERTISING: 'REFUSE_OF_ADVERTISING',
};

export const SIGN_AGREEMENT_TYPES_LABELS = {
  [SIGN_AGREEMENT_TYPES.TRANSFER_OF_CLAIM_TO_THIRD_PARTY]:
    'Я ознакомлен и согласен на взаимодействие Банка с третьими лицами в случае возникновения просроченной задолженности, передачу таким третьим лицам моих персональных данных в соответствии с условиями согласия, указанными в договоре',
  [SIGN_AGREEMENT_TYPES.REFUSE_OF_ADVERTISING]: 'Я отказываюсь от получения рекламных материалов',
};

export const PROPOSAL_STATUSES = {
  NEW: 'NEW',
  PRE_APPROVED: 'PRE_APPROVED',
  CONFIRMED: 'CONFIRMED',
  SIGNED: 'SIGNED',
  ACTIVE: 'ACTIVE',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  WAIT_APPROVE: 'WAIT_APPROVE',
  ERROR: 'ERROR',
};

export const APPLICATION_LIMIT_PROCESS_STATUSES = {
  PREPARED: 'PREPARED',
  PENDING: 'PENDING',
  LIMIT_APPROVED: 'LIMIT_APPROVED',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  ERROR: 'ERROR',
};

export const CANCELLATION_REASON_CODE = {
  LOW_BALANCE_OF_CREDIT_LINE: 'LOW_BALANCE_OF_CREDIT_LINE',
  LOW_APPROVED_LIMIT: 'LOW_APPROVED_LIMIT',
};
