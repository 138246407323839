import axios from 'axios';

import { API_CANCELED_MESSAGE } from './constants';
import { createDocumentAPIBaseUrl, createFrontAPIBaseUrl, createUrlShorterBaseUrl } from './config';

export const getCancelToken = cancelToken => {
  if (cancelToken) cancelToken.cancel(API_CANCELED_MESSAGE);
  return axios.CancelToken.source();
};

function createInstance(baseURL) {
  const instance = axios.create({
    validateStatus: status => status >= 200 && status < 400,
    baseURL,
  });

  instance.interceptors.response.use(
    response => response,
    error => Promise.reject(error),
  );

  return instance;
}

/** documentApiAxiosInstance  */

const documentApiBaseUrl = createDocumentAPIBaseUrl();

export const documentApiAxiosInstance = createInstance(documentApiBaseUrl);

/** frontApiAxiosInstance  */
const frontApiBaseUrl = createFrontAPIBaseUrl();

export const frontApiAxiosInstance = createInstance(frontApiBaseUrl);

/** urlShorterAxiosInstance  */
const urlShorterBaseUrl = createUrlShorterBaseUrl();

export const urlShorterAxiosInstance = createInstance(urlShorterBaseUrl);
