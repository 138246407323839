/* eslint-disable no-console */
export const jsonHeaders = { 'content-type': 'application/json' };

export const fileHeaders = { 'content-type': 'application/octet-stream' };

export const pdfHeaders = { 'content-type': 'application/pdf' };

const style1 = 'font-weight: normal; color: #888888;';
const style2 = 'font-weight: bold; color: #CCCCCC;';
const prefix = 'mock request';

export const logRequest = (config, response) => {
  if (process.env.REACT_APP_MOCK === 'true') {
    const shortConfigUrl = config.url.split('/');

    console.groupCollapsed(
      '%c %s %c%s',
      style1,
      prefix,
      style2,
      `${config.method.toUpperCase()} /${shortConfigUrl[shortConfigUrl.length - 1]}`,
    );

    if (config.data) {
      if (config.data instanceof FormData) console.log('file');
      else console.log('data', JSON.parse(config.data));
    }

    console.log('response', response[1]);

    console.groupEnd();
  }

  return response;
};

export function ApiError(message) {
  this.name = 'ApiError';
  this.message = message || '';
  this.stack = '';
}

ApiError.prototype = Object.create(Error.prototype);
ApiError.prototype.constructor = ApiError;

export const base64toBlob = (base64Data, contentType) => {
  if (base64Data.startsWith('data:')) {
    base64Data = base64Data.substring(base64Data.indexOf(',') + 1);
  }
  const cType = contentType || '';
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);
  for (let sliceIndex = 0; sliceIndex < slicesCount; sliceIndex += 1) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);
    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; i += 1, offset += 1) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: cType });
};

export function blobToBase64(blob) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}
