import { types } from 'mobx-state-tree';

import themes, { THEMES } from '@omay/ds-components/lib/_themes';

export const Store = types
  .model({
    selectedTheme: types.enumeration(Object.values(THEMES)),
  })

  .views(self => ({
    get theme() {
      return themes[self.selectedTheme];
    },
  }))

  .actions(self => ({
    setTheme: theme => {
      self.selectedTheme = theme;
    },
  }));

export const store = Store.create({
  selectedTheme: THEMES.MAIN,
});
