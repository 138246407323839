import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { observer } from 'mobx-react';

import Layout from '@omay/ds-components/lib/Layout';
import Toastify from '@omay/ds-components/lib/Toastify';

import Chat from './Chat';

import routing from './routing';
import { store as themeStore } from './theme';

function Application() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={themeStore.theme}>
        <Layout>
          <Switch>
            {routing.map(item => (
              <Route key={item.id} path={item.path} exact={item.exact}>
                <React.Suspense fallback={null}>
                  <item.Component />
                </React.Suspense>
              </Route>
            ))}
          </Switch>
        </Layout>
        <Toastify />
        <Chat />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default observer(Application);
