import { frontApiAxiosInstance, documentApiAxiosInstance } from 'api/axios';

import { URLS } from './constants';

function getStatus(id) {
  return frontApiAxiosInstance({
    method: 'GET',
    url: URLS.GET_STATUS.replace(':id', id),
  });
}

function getStep(id) {
  return frontApiAxiosInstance({
    method: 'GET',
    url: URLS.GET_STEP.replace(':id', id),
  });
}

function getEnrichment(id, params) {
  return frontApiAxiosInstance({
    method: 'POST',
    url: URLS.GET_ENRICHMENT.replace(':id', id),
    params,
  });
}

function getLimits(id) {
  return frontApiAxiosInstance({
    method: 'GET',
    url: URLS.GET_LIMITS.replace(':id', id),
  });
}

function getRequirementsDictionary() {
  return frontApiAxiosInstance({
    method: 'GET',
    url: URLS.GET_REQUIREMENTS_DICTIONARY,
  });
}

function getRequirements(id) {
  return frontApiAxiosInstance({
    method: 'GET',
    url: URLS.GET_REQUIREMENTS.replace(':id', id),
  });
}

function getOffers(id) {
  return frontApiAxiosInstance({
    method: 'GET',
    url: URLS.GET_OFFERS.replace(':id', id),
  });
}

function getCart(id) {
  return frontApiAxiosInstance({
    method: 'GET',
    url: URLS.GET_CART.replace(':id', id),
  });
}

function sendPreferences(id, data) {
  return frontApiAxiosInstance({
    method: 'POST',
    url: URLS.SEND_PREFERENCES.replace(':id', id),
    data,
  });
}

function getDecisions(id) {
  return frontApiAxiosInstance({
    method: 'GET',
    url: URLS.GET_DECISIONS.replace(':id', id),
  });
}

function sendProvider(id, providerId) {
  return frontApiAxiosInstance({
    method: 'PATCH',
    url: URLS.SEND_PROVIDER.replace(':id', id).replace(':providerId', providerId),
  });
}

function sendOffer(id, offerId, params) {
  return frontApiAxiosInstance({
    method: 'PATCH',
    url: URLS.SEND_OFFER.replace(':id', id).replace(':offerId', offerId),
    params,
  });
}

function sendSignType(id, signType) {
  return frontApiAxiosInstance({
    method: 'POST',
    url: URLS.SEND_SIGN_TYPE.replace(':id', id).replace(':signType', signType),
  });
}

function sendStartSigningProcess(id, signType) {
  return frontApiAxiosInstance({
    method: 'POST',
    url: URLS.SEND_START_SIGNING_PROCESS.replace(':id', id).replace(':signType', signType),
  });
}

function sendSmsToClient(id) {
  return frontApiAxiosInstance({
    method: 'POST',
    url: URLS.SEND_SMS_TO_CLIENT.replace(':id', id),
  });
}

function sendDocument(docType, file) {
  const formData = new FormData();
  formData.append('file', file);

  return documentApiAxiosInstance({
    method: 'POST',
    url: URLS.SEND_DOCUMENT,
    headers: {
      'Content-Type': `multipart/form-data; boundary=${formData._boundary}`, /* eslint-disable-line */
    },
    params: { documentType: docType },
    data: formData,
  });
}

function sendDocuments(id, data) {
  return documentApiAxiosInstance({
    method: 'POST',
    url: URLS.SEND_DOCUMENTS.replace(':id', id),
    data,
  });
}

export default {
  getStatus,
  getStep,
  getEnrichment,
  getLimits,
  getRequirementsDictionary,
  getRequirements,
  getOffers,
  getCart,
  sendPreferences,
  getDecisions,
  sendProvider,
  sendOffer,
  sendSignType,
  sendStartSigningProcess,
  sendSmsToClient,

  sendDocument,
  sendDocuments,
};
