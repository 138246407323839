export const URLS = {
  GET_STATUS: '/front-api/v1/applications/:id/status',
  GET_STEP: '/front-api/v1/blocks/:id/step',
  GET_ENRICHMENT: '/front-api/v1/online/applications/:id/enrichment',
  GET_LIMITS: '/front-api/v1/applications/:id/limits',
  GET_REQUIREMENTS_DICTIONARY: '/front-api/v1/dictionaries/references?referenceTypeCode=DESCRIPTION_DOCUMENT_TYPE',
  GET_REQUIREMENTS: '/front-api/v1/applications/:id/requirements',
  GET_OFFERS: '/front-api/v1/blocks/:id/offers',
  GET_CART: '/front-api/v1/blocks/:id/cart',
  SEND_PREFERENCES: '/front-api/v1/applications/:id/preferences',
  GET_DECISIONS: '/front-api/v1/applications/:id/statuses',
  SEND_PROVIDER: '/front-api/v2/applications/:id/decisions/:providerId',
  SEND_OFFER: '/front-api/v1/applications/:id/offers/:offerId',
  SEND_SIGN_TYPE: '/front-api/v1/applications/:id/signing-process/signtypes/:signType',
  SEND_START_SIGNING_PROCESS: '/front-api/v1/applications/:id/signing-process/start/:signType',
  SEND_SMS_TO_CLIENT: '/front-api/v1/applications/:id/send-sms-to-client',

  SEND_DOCUMENT: '/omay-document-front-api/v1/documents',
  SEND_DOCUMENTS: '/omay-document-front-api/v1/application/:id/document-types',
};

export const APPLICATION_STATUSES = {
  NEW: 'NEW',
  WAIT_APPROVE: 'WAIT_APPROVE',
  SIGNING_PROCESS: 'SIGNING_PROCESS',
  ERROR: 'ERROR',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  SIGNED: 'SIGNED',
};

export const APPLICATION_STEPS = {
  LIMITS_SHORT_DATA: 'LIMITS_SHORT_DATA',
  LIMITS_MORE_DATA: 'LIMITS_MORE_DATA',
  LIMITS_ADDSHORT_DATA: 'LIMITS_ADDSHORT_DATA',
  WAIT_LIMITS: 'WAIT_LIMITS',
  FILL_ADDITIONAL_FORM_DATA: 'FILL_ADDITIONAL_FORM_DATA',
  SELECT_PREFERENCES: 'SELECT_PREFERENCES',
  SELECT_PROVIDER: 'SELECT_PROVIDER',
  SELECT_OFFER: 'SELECT_OFFER',
  SELECT_SIGN_TYPE: 'SELECT_SIGN_TYPE',
  START_SIGNING_PROCESS: 'START_SIGNING_PROCESS',
  WAIT: 'WAIT',
};

export const APPLICATION_ACTIONS = {};

export const APPLICATION_CATEGORY_CODES = {
  POS: 'POS',
};

export const APPLICATION_CREATION_TYPES = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
};

export const APPLICATION_LIMIT_PROCESS_STATUSES = {
  PREPARED: 'PREPARED',
  PENDING: 'PENDING',
  LIMIT_APPROVED: 'LIMIT_APPROVED',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  ERROR: 'ERROR',
};

export const DECISIONS_STATUSES = {
  NOT_CREATED: 'NOT_CREATED',
  NEW: 'NEW',
  WAIT_APPROVE: 'WAIT_APPROVE',
  PRE_APPROVED: 'PRE_APPROVED',
  CONFIRMED: 'CONFIRMED',
  SIGNED: 'SIGNED',
  ACTIVE: 'ACTIVE',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  ERROR: 'ERROR',
};

export const EXTRA_PRODUCTS_TYPES = {
  SL: 'SL',
  SOPR: 'SL_SOPR',
  SMS: 'SMS',
};

export const EXTRA_PRODUCTS_PAYMENT_TYPES = {
  IN_BODY: 'IN_BODY',
  OUT_OF_BODY: 'OUT_OF_BODY',
};

export const SIGNING_TYPES = {
  MANUAL: 'MANUAL',
  PARTNER_SMS: 'PARTNER_SMS',
  PARTNER_FRONT_SMS: 'PARTNER_FRONT_SMS',
};
