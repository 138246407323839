import { flow, types } from 'mobx-state-tree';

import requests from '../requests';

import { create as createDocumentsStore, Store as DocumentsStore } from './documents';

export const GetStatus = types.model({
  status: types.maybeNull(types.string),
  creationType: types.maybeNull(types.string),
  categoryCode: types.maybeNull(types.string),
});

export const GetLimits = types.model({
  limitAmount: types.maybeNull(types.number),
  limitProcessStatus: types.maybeNull(types.string),
});

export const GetRequirementsDictionary = types.array(
  types.model({
    id: types.string,
    referenceCode: types.string,
    referenceTypeCode: types.string,
    value: types.string,
  }),
);

export const GetRequirements = types.array(
  types.model({
    documentType: types.maybeNull(types.string),
    collected: types.maybeNull(types.boolean),
    documentId: types.maybeNull(types.string),
  }),
);

export const OffersInitialPaymentValuesItem = types.model({
  paymentPercent: types.maybeNull(types.number),
  paymentSum: types.maybeNull(types.number),
});

export const OffersProductsItem = types.model({
  id: types.maybeNull(types.string),
  providerId: types.maybeNull(types.string),
  providerName: types.maybeNull(types.string),
  providerInn: types.maybeNull(types.string),
  annuityRate: types.maybeNull(types.number),
  minLimit: types.maybeNull(types.number),
  minPaymentTerm: types.maybeNull(types.number),
  maxPaymentTerm: types.maybeNull(types.number),
  maxLimit: types.maybeNull(types.number),
  minClientAge: types.maybeNull(types.number),
  maxClientAge: types.maybeNull(types.number),
  monthlyInterestRate: types.maybeNull(types.number),
  minInitialPaymentPercent: types.maybeNull(types.number),
  maxInitialPaymentPercent: types.maybeNull(types.number),
  minInitialPaymentAmount: types.maybeNull(types.number),
  maxInitialPaymentAmount: types.maybeNull(types.number),
  fixedInitialPaymentAmount: types.maybeNull(types.number),
  discount: types.maybeNull(types.number),
});

export const OffersSelectedPreference = types.model({
  paymentPercent: types.maybeNull(types.number),
  paymentSum: types.maybeNull(types.number),
  term: types.maybeNull(types.number),
});

export const OffersExtraProductsItem = types.model({
  id: types.maybeNull(types.string),
  producerId: types.maybeNull(types.string),
  inn: types.maybeNull(types.string),
  typeCode: types.maybeNull(types.string),
  paymentType: types.maybeNull(types.string),
  monthlyRate: types.maybeNull(types.number),
  monthlyRateAmount: types.maybeNull(types.number),
  rateMinLimit: types.maybeNull(types.number),
  rateMaxLimit: types.maybeNull(types.number),
});

export const GetOffers = types.model({
  initialPaymentValues: types.array(OffersInitialPaymentValuesItem),
  products: types.array(OffersProductsItem),
  termValues: types.array(types.number),
  selectedPreference: OffersSelectedPreference,
  extraProducts: types.maybeNull(types.array(OffersExtraProductsItem)),
});

export const CartItem = types.model({
  name: types.maybeNull(types.string),
  trademark: types.maybeNull(types.string),
  model: types.maybeNull(types.string),
  quantity: types.maybeNull(types.number),
  price: types.maybeNull(types.number),
  cost: types.maybeNull(types.number),
  type: types.maybeNull(types.string),
});

export const GetCart = types.model({
  items: types.array(CartItem),
  total: types.maybeNull(types.number),
});

export const DecisionsItemProductExtraProductsItem = types.model({
  categoryCode: types.string,
  active: types.maybeNull(types.boolean),
  sum: types.maybeNull(types.number),
});

export const DecisionsItemProduct = types.model({
  amount: types.maybeNull(types.number),
  annuityPay: types.maybeNull(types.number),
  confirmationId: types.maybeNull(types.string),
  initialPay: types.maybeNull(types.number),
  interestRate: types.maybeNull(types.number),
  paymentNum: types.maybeNull(types.number),
  extraProducts: types.maybeNull(types.array(DecisionsItemProductExtraProductsItem)),
});

export const DecisionsItem = types.model({
  availableActions: types.maybeNull(types.array(types.frozen())),
  product: types.maybeNull(DecisionsItemProduct),
  proposalId: types.maybeNull(types.string),
  providerId: types.maybeNull(types.string),
  providerInn: types.maybeNull(types.string),
  providerName: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  availableSigningTypes: types.maybeNull(types.array(types.string)),
  documentationDeliveryAvailable: types.maybeNull(types.boolean),
});

export const GetDecisions = types.model({
  providers: types.array(DecisionsItem),
  repeat: types.boolean,
});

export const Store = types
  .model({
    data: types.model({
      getStatus: types.model({ fetch: types.boolean, response: types.maybeNull(GetStatus) }),
      getStep: types.model({ fetch: types.boolean, response: types.maybeNull(types.string) }),
      getEnrichment: types.model({ fetch: types.boolean, response: types.maybeNull(types.boolean) }),
      getLimits: types.model({ fetch: types.boolean, response: types.maybeNull(GetLimits) }),
      getRequirementsDictionary: types.model({
        fetch: types.boolean,
        response: types.maybeNull(GetRequirementsDictionary),
      }),
      getRequirements: types.model({ fetch: types.boolean, response: types.maybeNull(GetRequirements) }),
      getOffers: types.model({ fetch: types.boolean, response: types.maybeNull(GetOffers) }),
      getCart: types.model({ fetch: types.boolean, response: types.maybeNull(GetCart) }),
      sendPreferences: types.model({ fetch: types.boolean }),
      getDecisions: types.model({ fetch: types.boolean, response: types.maybeNull(GetDecisions) }),
      sendProvider: types.model({ fetch: types.boolean }),
      sendOffer: types.model({ fetch: types.boolean }),
      sendSignType: types.model({ fetch: types.boolean }),
      sendStartSigningProcess: types.model({ fetch: types.boolean }),
      sendSmsToClient: types.model({ fetch: types.boolean }),
    }),

    documents: DocumentsStore,
  })
  .actions(self => ({
    getStatus: flow(function* getStatus(id) {
      try {
        self.data.getStatus.fetch = true;
        const result = yield requests.getStatus(id);
        const response = (result && result.data) || {};
        self.data.getStatus.response = response;
        self.data.getStatus.fetch = false;
        return response;
      } catch (e) {
        self.data.getStatus.response = null;
        self.data.getStatus.fetch = false;
        throw e;
      }
    }),

    getStep: flow(function* getStep(id) {
      try {
        self.data.getStep.fetch = true;
        const result = yield requests.getStep(id);
        const response = (result && result.data) || {};
        self.data.getStep.response = response;
        self.data.getStep.fetch = false;
        return response;
      } catch (e) {
        self.data.getStep.response = null;
        self.data.getStep.fetch = false;
        return null;
      }
    }),

    getEnrichment: flow(function* getEnrichment(id) {
      try {
        self.data.getEnrichment.fetch = true;
        const params = { type: 'FULL' };
        const result = yield requests.getEnrichment(id, params);
        const response = (result && result.data) || false;
        self.data.getEnrichment.response = response;
        self.data.getEnrichment.fetch = false;
        return response;
      } catch (e) {
        self.data.getEnrichment.response = null;
        self.data.getEnrichment.fetch = false;
        throw e;
      }
    }),

    getLimits: flow(function* getLimits(id) {
      try {
        self.data.getLimits.fetch = true;
        const result = yield requests.getLimits(id);
        const response = (result && result.data) || {};
        self.data.getLimits.response = response;
        self.data.getLimits.fetch = false;
        return response;
      } catch (e) {
        self.data.getLimits.response = null;
        self.data.getLimits.fetch = false;
        throw e;
      }
    }),

    getRequirementsDictionary: flow(function* getRequirementsDictionary() {
      try {
        self.data.getRequirementsDictionary.fetch = true;
        const result = yield requests.getRequirementsDictionary();
        const response = (result && result.data) || [];
        self.data.getRequirementsDictionary.response = response;
        self.data.getRequirementsDictionary.fetch = false;
        return response;
      } catch (e) {
        self.data.getRequirementsDictionary.response = null;
        self.data.getRequirementsDictionary.fetch = false;
        throw e;
      }
    }),

    getRequirements: flow(function* getRequirements(id) {
      try {
        self.data.getRequirements.fetch = true;
        const result = yield requests.getRequirements(id);
        const response = (result && result.data) || [];
        self.data.getRequirements.response = response;
        self.data.getRequirements.fetch = false;
        return response;
      } catch (e) {
        self.data.getRequirements.response = null;
        self.data.getRequirements.fetch = false;
        throw e;
      }
    }),

    getOffers: flow(function* getOffers(id) {
      try {
        self.data.getOffers.fetch = true;
        const result = yield requests.getOffers(id);
        const response = (result && result.data) || {};
        self.data.getOffers.response = response;
        self.data.getOffers.fetch = false;
        return response;
      } catch (e) {
        self.data.getOffers.response = null;
        self.data.getOffers.fetch = false;
        throw e;
      }
    }),

    getCart: flow(function* getCart(id) {
      try {
        self.data.getCart.fetch = true;
        const result = yield requests.getCart(id);
        const response = (result && result.data) || {};
        self.data.getCart.response = response;
        self.data.getCart.fetch = false;
        return response;
      } catch (e) {
        self.data.getCart.response = null;
        self.data.getCart.fetch = false;
        throw e;
      }
    }),

    sendPreferences: flow(function* sendPreferences(id, data) {
      try {
        self.data.sendPreferences.fetch = true;
        const result = yield requests.sendPreferences(id, data);
        const response = (result && result.data) || false;
        self.data.sendPreferences.fetch = false;
        return response;
      } catch (e) {
        self.data.sendPreferences.fetch = false;
        throw e;
      }
    }),

    getDecisions: flow(function* getDecisions(id) {
      try {
        self.data.getDecisions.fetch = true;
        const result = yield requests.getDecisions(id);
        const response = (result && result.data) || {};
        self.data.getDecisions.response = response;
        self.data.getDecisions.fetch = false;
        return response;
      } catch (e) {
        self.data.getDecisions.response = null;
        self.data.getDecisions.fetch = false;
        throw e;
      }
    }),

    sendProvider: flow(function* sendProvider(id, providerId) {
      try {
        self.data.sendProvider.fetch = true;
        const result = yield requests.sendProvider(id, providerId);
        const response = (result && result.data) || false;
        self.data.sendProvider.fetch = false;
        return response;
      } catch (e) {
        self.data.sendProvider.fetch = false;
        throw e;
      }
    }),

    sendOffer: flow(function* sendOffer(id, offerId, params) {
      try {
        self.data.sendOffer.fetch = true;
        const result = yield requests.sendOffer(id, offerId, params);
        const response = (result && result.data) || false;
        self.data.sendOffer.fetch = false;
        return response;
      } catch (e) {
        self.data.sendOffer.fetch = false;
        throw e;
      }
    }),

    sendSignType: flow(function* sendSignType(id, signType) {
      try {
        self.data.sendSignType.fetch = true;
        const result = yield requests.sendSignType(id, signType);
        const response = (result && result.data) || false;
        self.data.sendSignType.fetch = false;
        return response;
      } catch (e) {
        self.data.sendSignType.fetch = false;
        throw e;
      }
    }),

    sendStartSigningProcess: flow(function* sendStartSigningProcess(id, signType) {
      try {
        self.data.sendStartSigningProcess.fetch = true;
        const result = yield requests.sendStartSigningProcess(id, signType);
        const response = (result && result.data) || false;
        self.data.sendStartSigningProcess.fetch = false;
        return response;
      } catch (e) {
        self.data.sendStartSigningProcess.fetch = false;
        throw e;
      }
    }),

    sendSmsToClient: flow(function* sendSmsToClient(id) {
      try {
        self.data.sendSmsToClient.fetch = true;
        const result = yield requests.sendSmsToClient(id);
        const response = (result && result.data) || false;
        self.data.sendSmsToClient.fetch = false;
        return response;
      } catch (e) {
        self.data.sendSmsToClient.fetch = false;
        throw e;
      }
    }),
  }));

export function create() {
  return Store.create({
    data: {
      getStatus: { fetch: false, response: null },
      getStep: { fetch: false, response: null },
      getEnrichment: { fetch: false, response: null },
      getLimits: { fetch: false, response: null },
      getRequirementsDictionary: { fetch: false, response: null },
      getRequirements: { fetch: false, response: null },
      getOffers: { fetch: false, response: null },
      getCart: { fetch: false, response: null },
      sendPreferences: { fetch: false },
      getDecisions: { fetch: false, response: null },
      sendProvider: { fetch: false },
      sendOffer: { fetch: false },
      sendSignType: { fetch: false },
      sendStartSigningProcess: { fetch: false },
      sendSmsToClient: { fetch: false },
    },

    documents: createDocumentsStore(),
  });
}
