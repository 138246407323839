export function createDocumentAPIBaseUrl() {
  return process.env.REACT_APP_DOCUMENTS_API_HOST;
}

export function createFrontAPIBaseUrl() {
  return process.env.REACT_APP_APPLICATION_API_HOST;
}

export function createUrlShorterBaseUrl() {
  return process.env.REACT_APP_URL_SHORTER_URL_HOST;
}
