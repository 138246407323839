import { flow, types } from 'mobx-state-tree';

import requests from '../requests';

export const Store = types
  .model({
    data: types.model({
      sendDocument: types.model({ fetch: types.boolean }),
      sendDocuments: types.model({ fetch: types.boolean }),
    }),
  })
  .actions(self => ({
    sendDocument: flow(function* sendDocument(docType, file) {
      try {
        self.data.sendDocument.fetch = true;
        const result = yield requests.sendDocument(docType, file);
        const response = (result && result.data) || {};
        self.data.sendDocument.fetch = false;
        return response;
      } catch (e) {
        self.data.sendDocument.fetch = false;
        throw e;
      }
    }),

    sendDocuments: flow(function* sendDocuments(id, data) {
      try {
        self.data.sendDocuments.fetch = true;
        const result = yield requests.sendDocuments(id, data);
        const response = (result && result.data) || {};
        self.data.sendDocuments.fetch = false;
        return response;
      } catch (e) {
        self.data.sendDocuments.fetch = false;
        throw e;
      }
    }),
  }));

export function create() {
  return Store.create({
    data: {
      sendDocument: { fetch: false },
      sendDocuments: { fetch: false },
    },
  });
}
