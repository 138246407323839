import info1 from './info_1.json';
import info2 from './info_2.json';
import info3 from './info_3.json';
import info4 from './info_4.json';
import info5 from './info_5.json';

export const decisionInfoResponse = {
  info1,
  info2,
  info3,
  info4,
  info5,
};
