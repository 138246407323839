import AxiosMockAdapter from 'axios-mock-adapter';

import { documentApiAxiosInstance, frontApiAxiosInstance } from './axios';
import {
  frontApiMock as thirdPartyFrontApiMock,
  documentApiMock as thirdPartyDocumentApiMock,
} from './third-party/mock';
import {
  frontApiMock as identificationKNKFrontApiMock,
  documentApiMock as identificationKNKDocumentApiMock,
} from './identification-knk/mock';

import { frontApiMock as remoteRegistrationFrontApiMock } from './remote-registration/mock';
import { frontApiMock as applicationFrontApiMock } from './application-front/mock';

import { frontApiMock as agreementsFrontApiMock } from './agreements/mock';

export const configureAxiosMockAdapter = () => {
  switch (process.env.REACT_APP_PROJECT) {
    case 'IDENTIFICATION_KNK':
      identificationKNKFrontApiMock(new AxiosMockAdapter(frontApiAxiosInstance, { delayResponse: 500 }));
      identificationKNKDocumentApiMock(new AxiosMockAdapter(documentApiAxiosInstance, { delayResponse: 500 }));
      break;

    case 'THIRD_PARTY':
      thirdPartyFrontApiMock(new AxiosMockAdapter(frontApiAxiosInstance, { delayResponse: 500 }));
      thirdPartyDocumentApiMock(new AxiosMockAdapter(documentApiAxiosInstance, { delayResponse: 500 }));
      break;

    case 'REMOTE_REGISTRATION': {
      remoteRegistrationFrontApiMock(new AxiosMockAdapter(frontApiAxiosInstance, { delayResponse: 500 }));
      break;
    }

    case 'LITE_APPLICATION':
    case 'POS_ONLINE_NEW':
      applicationFrontApiMock(new AxiosMockAdapter(frontApiAxiosInstance, { delayResponse: 500 }));
      break;

    case 'AGREEMENTS': {
      agreementsFrontApiMock(new AxiosMockAdapter(frontApiAxiosInstance, { delayResponse: 500 }));
      break;
    }

    default:
      applicationFrontApiMock(new AxiosMockAdapter(frontApiAxiosInstance, { delayResponse: 500 }));
      break;
  }
};
